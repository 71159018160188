import React, { useEffect, useState } from 'react';
import About from './About';
import '../assets/css/home.css';
import saleDevelopment from '../img/homepage-sales-development-869x1024.png.webp';
import customerServices from '../img/homepage-customer-service-869x1024.png.webp';
import itNoperations from '../img/homepage-IT-and-operations-869x1024.png.webp';
import line from '../img/homepage-line-managers-869x1024.png.webp';
import logo from '../img/logoGif.gif';
import support1 from '../img/softwareDevelopment4.jpg';
import powerDialer from '../img/icon-power-dialer-featured.svg';
import clickToCall from '../img/icon-click-to-call-featured.svg';
import automaticSummary from '../img/icon-automatic-call-summary-tags-featured.svg';
import numberPorting from '../img/icon-number-porting-featured.svg';
import calltransfer from '../img/icon-call-transfers-featured.svg';
import IVR from '../img/icon-interactive-voice-response-featured.svg';
import finiancialService from '../img/seo4.webp';
import integrationImg from '../img/homepage-integrations-stripe2.svg'

function HomePage() {
    const [whoWeHelp, setWhoWeHelp] = useState('sale');
    useEffect(()=>{
        const mobile = document.getElementById('mobile')
        mobile.click();
    },[])
    return (
        <>
            <About />
            <div className='who-we-help row my-md-5 my-0'>
                <div className='col-md-6 col-12 px-md-5 px-3'>
                    <p className='text-secondary text-bold'>Who we help</p>
                    <p className='head'>Enabling Your Entire Revenue Team to Focus on What Truly Matters</p>
                    <div className='button-group mt-md-4 mt-2'>
                        <button className='btn btn-light rounded-pill' onClick={() => setWhoWeHelp('sale')}>Sale Development</button>
                        <button className='btn btn-light rounded-pill' onClick={() => setWhoWeHelp('customer')}>Customer Service</button>
                        <button className='btn btn-light rounded-pill' onClick={() => setWhoWeHelp('it')}>It Operations</button>
                        <button className='btn btn-light rounded-pill' onClick={() => setWhoWeHelp('line')}>Line Manager</button>
                    </div>
                    <div className='my-4 changing-content'>
                        {whoWeHelp == 'sale' && <>
                            <p className='subhead'>Achieve Your Call Targets</p>
                            <p className='subhead2'>Optimize processes and ease CRM updates.</p>
                            <ul className='my-5' style={{ listStyle: "none", paddingLeft: "1px" }}>
                                <li className='d-flex'>
                                    <p><i className="bi bi-box text-primary"></i></p>
                                    <p className='mx-3'>
                                        <span className='fw-bold text-dark who-we-help-main'>Dialers & Voicemail Drop:</span>
                                        <span className=' ms-1 who-we-help-content'>Eliminate wasted time and reach more prospects globally.</span>
                                    </p>

                                </li>
                                <li className='d-flex'>
                                    <p><i className="bi bi-box text-primary"></i></p>
                                    <p className='mx-3'>
                                        <span className='fw-bold text-dark who-we-help-main'>Custom Campaigns & CRM Integration:</span>
                                        <span className=' ms-1 who-we-help-content'> Craft targeted calls with customer details at your fingertips.</span>
                                    </p>
                                </li>

                                <li className='d-flex'>
                                    <p><i className="bi bi-box text-primary"></i></p>
                                    <p className='mx-3'>
                                        <span className='fw-bold text-dark who-we-help-main'>Call Analytics & Recording:</span>
                                        <span className=' ms-1 who-we-help-content'>Track performance and analyze calls to continuously improve your sales strategy.</span>
                                    </p>

                                </li>
                            </ul>
                        </>}
                        {whoWeHelp == 'customer' && <>
                            <p className='subhead'>Reduce Missed Calls</p>
                            <p className='subhead2'>Smart call routing and robust automation tools.</p>
                            <ul className='my-5' style={{ listStyle: "none", paddingLeft: "1px" }}>
                                <li className='d-flex'>
                                    <p><i className="bi bi-box text-primary"></i></p>
                                    <p className='mx-3'>
                                        <span className='fw-bold text-dark who-we-help-main'>Call Queuing & IVR:</span>
                                        <span className='who-we-help-content-1'>Manage call volume efficiently and empower customers with self-service options.</span>
                                    </p>

                                </li>
                                <li className='d-flex'>
                                    <p><i className="bi bi-box text-primary"></i></p>
                                    <p className='mx-3'>
                                        <span className='fw-bold text-dark who-we-help-main'>3 Way Calling & Voicemail:</span>
                                        <span className='who-we-help-contentms-1'> Collaborate with colleagues and capture messages after hours for uninterrupted service.</span>
                                    </p>
                                </li>

                                <li className='d-flex'>
                                    <p><i className="bi bi-box text-primary"></i></p>
                                    <p className='mx-3'>
                                        <span className='fw-bold text-dark who-we-help-main'>Missed Call Analysis & AI Insights:</span>
                                        <span className='who-we-help-content ms-1'>Identify trends and leverage AI to provide exceptional customer support.</span>
                                    </p>

                                </li>
                            </ul>
                        </>}
                        {whoWeHelp == 'it' && <>
                            <p className='subhead'>Smooth Integration</p>
                            <p className='subhead2'>Obtain valuable insights, maintain reliability, and integrate effortlessly. Automate tasks to free up your team for strategic objectives.</p>
                            <ul className='my-5' style={{ listStyle: "none", paddingLeft: "1px" }}>
                                <li className='d-flex'>
                                    <p><i className="bi bi-box text-primary"></i></p>
                                    <p className='mx-3'>
                                        <span className='fw-bold text-dark who-we-help-main'>CRM & Workflow Automation:</span>
                                        <span className='who-we-help-content ms-1'> Streamline workflows, automate tasks, and simplify agent logins for a seamless experience.</span>
                                    </p>

                                </li>
                                <li className='d-flex'>
                                    <p><i className="bi bi-box text-primary"></i></p>
                                    <p className='mx-3'>
                                        <span className='fw-bold text-dark who-we-help-main'>Skill-Based Routing & Business Hours:</span>
                                        <span className='who-we-help-contentms-1'> Optimize agent assignments and set call center availability for global operations.</span>
                                    </p>
                                </li>

                                <li className='d-flex'>
                                    <p><i className="bi bi-box text-primary"></i></p>
                                    <p className='mx-3'>
                                        <span className='fw-bold text-dark who-we-help-main'>International Numbers & Spam Protection:</span>
                                        <span className='who-we-help-content ms-1'>Expand your reach, control costs, and ensure data security.</span>
                                    </p>

                                </li>
                            </ul>
                        </>}
                        {whoWeHelp == 'line' && <>
                            <p className='subhead'>Hit Team Goals</p>
                            <p className='subhead2'>Call data, agent analytics, and automated routing help you empower, enable and coach your people.</p>
                            <ul className='my-5' style={{ listStyle: "none", paddingLeft: "1px" }}>
                                <li className='d-flex'>
                                    <p><i className="bi bi-box text-primary"></i></p>
                                    <p className='mx-3'>
                                        <span className='fw-bold text-dark who-we-help-main'>Agent Reporting & Analytics:</span>
                                        <span className='who-we-help-contentms-1'>Track individual and team performance metrics to identify trends and develop targeted coaching programs.</span>
                                    </p>

                                </li>
                                <li className='d-flex'>
                                    <p><i className="bi bi-box text-primary"></i></p>
                                    <p className='mx-3'>
                                        <span className='fw-bold text-dark who-we-help-main'>Real-Time Dashboard & Call Recording:</span>
                                        <span className='who-we-help-content ms-1'> Monitor activity, review calls, and provide real-time coaching for continuous improvement.</span>
                                    </p>
                                </li>

                                <li className='d-flex'>
                                    <p><i className="bi bi-box text-primary"></i></p>
                                    <p className='mx-3'>
                                        <span className='fw-bold text-dark who-we-help-main'>User Management & Skill-Based Routing:</span>
                                        <span className='who-we-help-contentms-1'>Manage agents, automate tasks, and optimize routing to ensure your team has the resources they need.</span>
                                    </p>

                                </li>
                            </ul>
                        </>}
                    </div>
                </div>
                <div className='col-md-6 col-12 px-5'>
                    {whoWeHelp == 'sale' && <img src={saleDevelopment} className='w-100' alt="" />}
                    {whoWeHelp == 'customer' && <img src={customerServices} className='w-100' alt="" />}
                    {whoWeHelp == 'it' && <img src={itNoperations} className='w-100' alt="" />}
                    {whoWeHelp == 'line' && <img src={line} className='w-100' alt="" />}

                </div>
            </div>

            <div className='my-5 unrealiable-call'>
                <p className='head'>
                    Unreliable Calls? Limited Reach? You Deserve Better.
                </p>
                <p className='fs-5 fw-normal text-secondary'>Don't settle for dropped calls and restricted connections. The Autodial ensures smooth, dependable calls with a global reach, allowing you to connect with every customer, everywhere.</p>
            </div>
            <div className='difference'>
                <table className='shadow rounded w-100'>
                    <tr>
                        <td className='w-100'> 
                            <img  src={logo} alt=""/>Crystal-clear call quality for uninterrupted communication.</td>
                    </tr>
                    <tr>
                        <td>
                            <img  src={logo} alt="" width='50' height='50'  />Effortless access to localized numbers in over 160 countries.</td>
                    </tr>
                    <tr>
                        <td>
                            <img  src={logo} alt="" width='50' height='50'  />Seamless integrations with your CRM, helpdesk, and other business tools.</td>
                    </tr>
                    <tr>
                        <td>
                            <img src={logo} alt="" width='50' height='50'  />Easy-to-use software for a smooth adoption process.</td>
                    </tr>
                </table>
            </div>

            <div className='support-industry'>
                <div className='row'>
                    <div className='col-6 p-4'>
                        <img src={support1} className='w-100 rounded' alt="/" />
                    </div>
                    <div className='col-6 p-md-4 p-2 '>
                        <p className='head'>Software & Tech</p>
                        {window.innerWidth > 576 &&
                            <>
                                <p className='content'>Boost efficiency with features like power dialing and intelligent call routing. Close deals, resolve issues faster and get a 360 customer view with powerful CRM and helpdesk integrations.</p>
                                <div>
                                    <p className='subhead'>
                                        5 favourite industry features
                                    </p>
                                    <div className='subhead-content'>
                                        <p><img src={powerDialer} alt="" /> Power Dialer</p>
                                        <p><img src={clickToCall} alt="" /> Click-to-Call</p>
                                        <p><img src={automaticSummary} alt="" /> Automatic Call Summary & Tags</p>
                                        <p><img src={numberPorting} alt="" /> Number Porting</p>
                                    </div>
                                </div>
                            </>}
                    </div>

                    {window.innerWidth <= 576 &&
                        <div className='col-12 row'>
                            <div className='col-6'>
                                <p className='content'>Boost efficiency with features like power dialing and intelligent call routing. Close deals, resolve issues faster and get a 360 customer view with powerful CRM and helpdesk integrations.</p>
                            </div>
                            <div className='col-6'>
                                <p className='subhead'>
                                    5 favourite industry features
                                </p>
                                <div className='subhead-content'>
                                    <p><img src={powerDialer} alt="" /> Power Dialer</p>
                                    <p><img src={clickToCall} alt="" /> Click-to-Call</p>
                                    <p><img src={automaticSummary} alt="" /> Automatic Call Summary & Tags</p>
                                    <p><img src={numberPorting} alt="" /> Number Porting</p>
                                </div>
                            </div>
                        </div>}

                    <div className='col-6 px-5 py-4'>
                        <p className='head'>Financial Services</p>
                        {window.innerWidth > 576 &&
                            <>
                                <p className='content'>Ditch the complex phone systems and dedicate your expertise to providing superior financial advice. Our solution scales effortlessly with your client base, allowing you to add or remove lines as needed. Keep costs predictable while guaranteeing seamless communication with your growing clientele.</p>
                                <div>
                                    <p className='subhead'>
                                        5 favourite industry features
                                    </p>
                                    <div className='subhead-content'>
                                        <p><img src={calltransfer} alt="" /> Call Transfers</p>
                                        <p><img src={IVR} alt="" /> Interactive Voice Response (IVR)</p>
                                        <p><img src={automaticSummary} alt="" /> Automatic Call Summary & Tags</p>
                                        <p><img src={numberPorting} alt="" /> Number Porting</p>
                                    </div>
                                </div>
                            </>}
                    </div>
                    <div className='col-6 p-4'>
                        <img src={finiancialService} className='w-100 rounded' alt="/" />
                    </div>
                    {window.innerWidth <= 576 &&
                        <div className='col-12 row'>
                            <div className='col-6'>
                                <p className='content'>Ditch the complex phone systems and dedicate your expertise to providing superior financial advice. Our solution scales effortlessly with your client base, allowing you to add or remove lines as needed. Keep costs predictable while guaranteeing seamless communication with your growing clientele.</p>
                            </div>
                            <div className='col-6'>
                                <p className='subhead'>
                                    5 favourite industry features
                                </p>
                                <div className='subhead-content'>
                                    <p><img src={calltransfer} alt="" /> Call Transfers</p>
                                    <p><img src={IVR} alt="" /> Interactive Voice Response (IVR)</p>
                                    <p><img src={automaticSummary} alt="" /> Automatic Call Summary & Tags</p>
                                    <p><img src={numberPorting} alt="" /> Number Porting</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className='integrations p-md-5 p-2 my-5' >
                <img src={integrationImg} className='w-100' alt="" />
                <div className=''>
                    <p className='head'>An Essential Member of Your Elite Team</p>
                    <p className='content'>The Autodial integrates with your CRM, helpdesk, enablement, and intelligence tools to craft a successful communication strategy.</p>
                </div>
                <img src={integrationImg} className='w-100' alt="" />
                <a href="tel: +91 8826450057" id='mobile' style={{ textDecorationLine: 'none', fontSize: '14px', visibility:'hidden' }}><i className="fas fa-phone me-2"></i>India <img className='flags' src='' alt="" /><span className='phone-number'>+91 8826450057</span></a>

            </div>
        </>
    );
}
export default HomePage;

import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import jdLogo from '../img/theautodialLogo.png';
import { homeScroll } from '../smoothScroll';
import autodialLogo from '../img/logoGif.gif';
import '../assets/css/viewLead.css'
export default function ViewLeads() {
    const [leads, setLeads] = useState([]);
    const navigate = useNavigate()
    const header = [
        'Sr. No.', 'First Name', 'Last Name', 'Email', 'Phone', 'Aadhaar No.', 'Pan No.', 'Aadhaar', 'Pan'
    ]
    const getLeads = async () => {
        const response = await fetch('https://jdtechnicalsolution.com/getLeads', {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        });
        try {
            if (response.ok) {
                const data = await response.json()
                setLeads(data.reverse());
            }
            else {
                console.log('cant fetch data');
            }
        } catch (error) {
            console.log('some error occured', error)
        }
    }
    useEffect(() => {
        getLeads()
    }, [])

    return (
        <div className='viewLead'>

            <div className='nav'>
                <Link to="/" onClick={(e) => { homeScroll('about') }} className="navbar-brand p-0 d-flex">
                    <img className='rounded logo' src={autodialLogo} alt="Logo" /><img className='rounded logo ms-2' src={jdLogo} alt="Logo" />
                </Link>
            </div>
            <div className='p-3 '>
                <div className='shadow rounded px-3 p-2'>
                    <span className='px-1 rounded border fw-bold'>Leads</span>
                </div>
                <div className='rounded shadow px-3 p-2 my-3'>
                    <table className='w-100 p-2 text-center'>
                        <thead>
                            <tr>
                                {
                                    header.map((field, index) => (
                                        <td className='p-2 fw-bold' key={index}>
                                            {field}
                                        </td>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {

                                leads.map((lead, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        {
                                            lead.filter((data, index) => (![0, 7, 8].includes(index))).map((field, index) => (
                                                <td key={index}>
                                                    {field}
                                                </td>
                                            ))
                                        }
                                        <td className='aadhaar' onClick={() => window.location.href = `https://www.theautodial.com/static/media/registeration_images/${lead[lead.length - 2]}`}>
                                            <img className='' src={`https://www.theautodial.com/static/media/registeration_images/${lead[lead.length - 2]}`} alt="Aadhaar" />
                                        </td>
                                        <td className='pan' onClick={() => window.location.href = `https://www.theautodial.com/static/media/registeration_images/${lead[lead.length - 1]}`}>
                                            <img className='' src={`https://www.theautodial.com/static/media/registeration_images/${lead[lead.length - 1]}`} alt="Pan" />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}


// MinimalLayout.jsx
import React from 'react';
import Footer from '../components/Footer';
const MinimalLayout = ({ children }) => (
  <>
    {children}
    <Footer/>
  </>
);

export default MinimalLayout;